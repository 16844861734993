import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, PrintJobItem, SwapIconEffect } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';

const ListSites: IScreen = () => {
  const { navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const SiteStore = Store.useSiteStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [sites, setSites] = useState<Array<TSite>>([]);


  const getData = async () => {
    try {
      setUI({ fetching: true, errorMes: '' });
      await TimeHelper.wait(300);
      const { list, hasNext, total, error } = await SiteStore.getList();
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: true, errorMes: error });
      setPagination({ hasNext, total });
      setSites(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, []);

  useEffect(() => {

  }, []);

  const renderJobList = () => {
    if (sites.length === 0) {
      return (
        <Col flex1>
          <Text>No site found</Text>
        </Col>
      )
    }
    return (
      <Col flex1>
        <Row
          height={50} stretch
          borderBottomColor={COLOR.GREY_BG}
          borderBottomWidth={1}
        >
          <Col width={15} m1 />
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Name</Text>
          </Col>
          <Col flex1 minWidth={150} m1 mr2>
            <Text color={COLOR.GREY}>Logo</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Slug</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Created At</Text>
          </Col>
          <Col width={50} m1 />
        </Row>
        <Col>
          {sites.map((s, sI) => {
            return (
              <Row key={s.id} height={50}>
                <Col width={15} height={15} m1 backgroundColor={s.theme?.mainColor} />
                <Col flex1 m1>
                  <Text>{s.name}</Text>
                </Col>
                <Col flex1 minWidth={150} m1 mr2>
                  {Boolean(s.logo) && (
                    <Image source={{ uri: s.logo }} style={{ width: 150, height: 50 }} resizeMode='contain' />
                  )}  
                </Col>
                <Col flex1 m1>
                  <Text>{s.slug}</Text>
                </Col>
                <Col flex1 m1>
                  <Text>{TimeHelper.formatDay(s.createdAt)}</Text>
                </Col>
                <Col width={50} m1 >
                  <TouchField cirle middle
                    onPress={() => {
                      navigation.reset({
                        index: 0,
                        routes: [{name: SCREEN.UpsertSite, params: { id: s.id }}],
                      });
                    }}
                  >
                    <SVG.Pencil width={20} height={20} />
                  </TouchField>
                </Col>
              </Row>
            )
          })}
        </Col>
      </Col>
    )
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >List sites</Text>
        <Button
          text="Add site"
          width={200} height={40} borderRadius={20}
          onPress={async () => {
            navigation.reset({
              index: 0,
              routes: [{name: SCREEN.UpsertSite, params: { id: 'new' }}],
            });
          }}
        />
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Col>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
            </Col>
          ) : (
            renderJobList()
          )
        )}
      </Col>
    </CMSLayout>
  )
};

ListSites.routeInfo = {
  title: 'List Sites',
  path: '/sites',
};

export default ListSites;