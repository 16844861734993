
class RequestHelper {

  _token = '';
  setToken = (t) => {
    this._token = t;
    if (typeof window === 'undefined') return;
    localStorage.setItem('token', t);
  }
  getToken = () => {
    if (this._token) return this._token;
    const fromSaved = localStorage.getItem('token');
    if (fromSaved) {
      this._token = fromSaved;
      return fromSaved
    }
    return '';
  }

  host = '';
  setHost = (h) => {
    this.host = h;
  }
  h = (path = '') => {
    let url = this.host + path;
    if (this.host.endsWith('/') && path.startsWith('/')) {
      url = url.replace('//', '/');
    }
    return url;
  }

  makeHeader = (method, isUpload = false) => {
    let token = this.getToken();
    let headers = {
      'Accept': 'application/json, text/plain, */*',
    };
    if(token) headers["Authorization"] = token;
    if(method === "POST" || method === "PUT" ) headers["Content-Type"] = "application/json";
    if(isUpload) {
      // headers["Content-Type"] = 'multipart/form-data';
      // @ts-ignore
      delete headers["Accept"];
      delete headers["Content-Type"];
    }
    return headers;
  }
  querify = (url, queryObject) => {
    let newUrl = url;
    if(!queryObject) return newUrl;
    let queryString = '';
    for (let key in queryObject) {
      queryString += queryString.length > 0 ? '&' : '';
      queryString += `${key}=${queryObject[key]}`;
    }
    newUrl += "?" + queryString;
    return newUrl;
  }

  returnFetchResponse = async (response) => {
    const contentType = response.headers.get('content-type');
    if (contentType === 'application/pdf') {
      let data = await response.blob();
      return {
        headers: response.headers,
        data: data,
      };
    }
    let data = await response.text();
    try {
      data = JSON.parse(data);
    } catch(_) {}
    return {
      headers: response.headers,
      data: data,
    };
  }

  get = async (URL, queryObject) => {
    const urlWithQuery = this.querify(this.h(URL), queryObject);
    const res = await fetch(urlWithQuery, {
      method: 'GET',
      headers: this.makeHeader("GET"),
    });
    return await this.returnFetchResponse(res);
  }

  post = async (URL, bodyObject) => {
    const res = await fetch(this.h(URL), {
      method: 'POST',
      headers: this.makeHeader("POST"),
      body: JSON.stringify(bodyObject),
    });
    return await this.returnFetchResponse(res);
  }

  put = async (URL, bodyObject) => {
    const res = await fetch(this.h(URL), {
      method: 'PUT',
      headers: this.makeHeader("POST"),
      body: JSON.stringify(bodyObject),
    });
    return await this.returnFetchResponse(res);
  }

  upload = async (URL: string, file: any) => {
    const formData = new FormData();
    formData.append('file',file);
    const res = await fetch(this.h(URL), {
      method: 'POST',
      headers: this.makeHeader("POST", true),
      body: formData,
    });
    return await this.returnFetchResponse(res);
  }

  delete = async (URL, queryObject) => {
    const urlWithQuery = this.querify(this.h(URL), queryObject);
    const res = await fetch(urlWithQuery, {
      method: 'DELETE',
      headers: this.makeHeader("GET"),
    });
    return await this.returnFetchResponse(res);
  }

  call = async (URL, method, params, query, body) => {
    let url = this.h(URL);
    if (query) url = this.querify(url, query);
    if (params) {
      for (let key in params) {
        url = url.replace(':' + key, params[key]);
      }
    }
    const res = await fetch(url, {
      method: method.toUpperCase(),
      headers: this.makeHeader(
        ['GET', 'DELETE'].includes(method.toUpperCase()) ? 'GET' : 'POST'
      ),
      body: ['GET', 'DELETE'].includes(method.toUpperCase()) ? undefined : JSON.stringify(body),
    });
    return await this.returnFetchResponse(res);
  }
}

export default new RequestHelper();