import { Col, Text, CMSLayout, Row, TouchField, Button, Input, useUIState } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, ScrollView } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { Image } from 'react-native';
import { saveAs } from 'file-saver';

const CheckOrderShopifyData = ({ orderData, orderId, errorData: errorData2 }: any) => {
  const [shouldAddToAdestra, setShouldAddToAdestra] = useState(false);
  const [shouldAddToRaiserEdge, setShouldAddToRaiserEdge] = useState(false);
  const [shouldSendtoHP, setShouldSendtoHP] = useState(true);
  const [shouldSendEcard, setShouldSendEcard] = useState(false);
  const [{ loading }, setUI] = useUIState();
  const [{ loading: isSubmitting }, setSubmitUI] = useUIState();
  const [errorData, setErrorData] = useState<any>(errorData2);
  const isTommys = window.location.hostname.includes('tommys');
  const isHood = window.location.hostname.includes('hood');

  const downloadPDF = async (id, lineId) => {
    const res = await Store.Api.Shop.hoodDownloadPDF({ orderId:  String(id), lineId });
    if (res.data.error) {
      return alert(res.data.error);
    }
    await saveAs(res.data, `print-file.pdf`);
  }

  const sendHP = async (id, lineId) => {
    const res = await Store.Api.Shop.hoodSendHPSingleLine({
      orderId: String(id),
      lineId,
    });
    if (res.data.success) {
      alert('Sent to HP sucessfully');
    } else if (res.data.error) {
      alert(res.data.error);
    }
  }

  return !orderData ? null : (
    <Col mb2>

      <Col mb2>
        <Row p1>
          <Col flex={2} m0 p0>
            <Text bold>Product Name</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Product ID - Variant ID</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>SKU</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Quantity</Text>
          </Col>
          <Col flex1 m0 p0></Col>
        </Row>
        {orderData.line_items.map((v, i) => {
          return (
            <Row p1 key={orderData.id + '-' + v.id} backgroundColor={i % 2 === 0 ? 'rgba(0,0,0,0.08)' : undefined}>
              <Col flex={2} m0 p0>
                <Text>{v.name}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.product_id} - {v.id}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.sku}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.quantity}</Text>
              </Col>
              <Row flex1 m0 p0>
                <TouchField mr1 p1 onPress={() => downloadPDF(orderData.id , v.id)}>
                  <Row>
                    <FontAwesome5 name="file-pdf" size={20} color="black" />
                    <Text ml0>PDF</Text>
                  </Row>
                </TouchField>

                <TouchField p1 onPress={() => sendHP(orderData.id , v.id)}>
                    <Row>
                      <Text ml0>Send HP</Text>
                    </Row>
                  </TouchField>
              </Row>
            </Row>
          )
        })}

      </Col>

      <Text mb1>Here is JSON data for order: {orderData.id}</Text>
      <Row alignItems={'flex-start'} marginHorizontal={-5}>
        <Col flex1 height={400} m0 mb2>
          <Text mb1>Full Data</Text>
          <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
            <Text>{JSON.stringify(orderData, undefined, 4)}</Text>
          </ScrollView>
        </Col>
        <Col flex1 height={400} m0 mb2>
          <Text mb1>Customer Data</Text>
          <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
            <Text>{JSON.stringify(orderData.customer, undefined, 4)}</Text>
          </ScrollView>
        </Col>
      </Row>
      <Row alignItems={'flex-start'}>
        <Col flex1 height={400} m0 mb2>
          <Text mb1>Shipping Data</Text>
          <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
            <Text>{JSON.stringify(orderData.shipping_address, undefined, 4)}</Text>
          </ScrollView>
        </Col>
        <Col flex1 height={400} m0 mb2>
          <Text mb1>Products Data</Text>
          <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
            <Text>{JSON.stringify(orderData.line_items, undefined, 4)}</Text>
          </ScrollView>
        </Col>
      </Row>
      {Boolean(isTommys) && (
        <>
          <Col>
            <Text bold mb1>Do you want to resubmit the order?</Text>

            <TouchField borderRadius={0} mb0 onPress={() => setShouldAddToAdestra(!shouldAddToAdestra)} width={300}>
              <Row>
                <Col
                  width={20} height={20} borderColor={COLOR.MAIN} borderRadius={4} borderWidth={2}
                  backgroundColor={shouldAddToAdestra ? COLOR.MAIN : 'transparent'}
                />
                <Text ml0>Should add to Adestra?</Text>
              </Row>
            </TouchField>
            <TouchField borderRadius={0} mb0 onPress={() => setShouldAddToRaiserEdge(!shouldAddToRaiserEdge)} width={300}>
              <Row>
                <Col
                  width={20} height={20} borderColor={COLOR.MAIN} borderRadius={4} borderWidth={2}
                  backgroundColor={shouldAddToRaiserEdge ? COLOR.MAIN : 'transparent'}
                />
                <Text ml0>Should add to Raiser Edge?</Text>
              </Row>
            </TouchField>
            <TouchField borderRadius={0} mb0 onPress={() => setShouldSendEcard(!shouldSendEcard)} width={300}>
              <Row>
                <Col
                  width={20} height={20} borderColor={COLOR.MAIN} borderRadius={4} borderWidth={2}
                  backgroundColor={shouldSendEcard ? COLOR.MAIN : 'transparent'}
                />
                <Text ml0>Should send Ecard?</Text>
              </Row>
            </TouchField>
            <TouchField borderRadius={0} mb0 onPress={() => setShouldSendtoHP(!shouldSendtoHP)} width={300}>
              <Row>
                <Col
                  width={20} height={20} borderColor={COLOR.MAIN} borderRadius={4} borderWidth={2}
                  backgroundColor={shouldSendtoHP ? COLOR.MAIN : 'transparent'}
                />
                <Text ml0>Should send to HP?</Text>
              </Row>
            </TouchField>
          </Col>
          <Button
            text="Re-Submit"
            isLoading={isSubmitting}
            onPress={async () => {
              setSubmitUI({ loading: true });
              try {
                const res = await Store.Api.Shop.rerunOrder({
                  id: orderId,
                  shouldAddToAdestra,
                  shouldAddToRaiserEdge,
                  shouldSendEcard,
                  shouldSendtoHP
                });
                if (res.data.errorMes) {
                  setErrorData(res.data);
                } else {
                  alert('Re-submit successfully');
                }
              } catch (err) { }
              setSubmitUI({ loading: false });
            }}
          />
        </>
      )}
      {Boolean(isHood) && (
        <Button
          text="Send HP"
          isLoading={isSubmitting}
          onPress={async () => {
            setSubmitUI({ loading: true });
            try {
              const res = await Store.Api.Shop.hoodSendHP({ orderId: orderId || String(orderData.id) })
              if (res.data.errorMes) {
                setErrorData(res.data);
              } else {
                alert('Send HP successfully');
              }
            } catch (err) { }
            setSubmitUI({ loading: false });
          }}
        />
      )}
      {errorData && (
        <Col mb2>
          <Col flex1 height={400} m0 mb2>
            <Text mb1>Error Data</Text>
            <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
              <Text>{JSON.stringify(errorData, undefined, 4)}</Text>
            </ScrollView>
          </Col>
        </Col>
      )}
    </Col>
  );
};

export default CheckOrderShopifyData;