import Request from '../Request.utils'
export interface IShopifyWebhookRequestParams {
  id?: string;
}
export interface ICheckOrderRequestBody {
  id?: string;
  name?: string;
}
export interface IRerunOrderRequestBody {
  id?: string;
  shouldAddToAdestra?: boolean;
  shouldAddToRaiserEdge?: boolean;
  shouldSendEcard?: boolean;
  shouldSendtoHP?: boolean;
}
export interface IHoodGenerateImageRequestParams {
  id?: string;
}
export interface IHoodGeneratePageContentImageRequestParams {
  id?: string;
}
export interface IHoodDownloadPDFRequestBody {
  duplicatePage?: number;
  id?: string;
  lineId?: number;
  orderId?: string;
}
export interface IHoodSendHPRequestBody {
  id?: string;
  orderId?: string;
}
export interface IHoodSendHPSingleLineRequestBody {
  lineId?: string;
  orderId?: string;
}
export interface IOrderToArtworkRequestBody {
  lineId?: string;
  orderId?: string;
}
export interface IManuallySendHPRequestBody {
  items?: {
    pdfUrl?: string;
    quantity?: number;
    sku?: string;
    sourceItemId?: string;
  }[];
  orderData?: {
    customerName?: string;
    sourceOrderId?: string;
  };
  shipTo?: {
    address1?: string;
    address2?: string;
    isoCountry?: string;
    name?: string;
    postcode?: string;
    town?: string;
  };
}


class ShopAPI {
  shopifyWebhook = async (params: IShopifyWebhookRequestParams) => {
    const res = await Request.call('/api/online-stores/shopify-webhook', 'POST', params, undefined, undefined, );
    return res;
  }
  hpWebhook = async () => {
    const res = await Request.call('/api/online-stores/hp-webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
  checkOrder = async (body: ICheckOrderRequestBody) => {
    const res = await Request.call('/api/online-stores/check-order', 'POST', undefined, undefined, body, );
    return res;
  }
  rerunOrder = async (body: IRerunOrderRequestBody) => {
    const res = await Request.call('/api/online-stores/rerun-order', 'POST', undefined, undefined, body, );
    return res;
  }
  hoodGenerateImage = async (params: IHoodGenerateImageRequestParams) => {
    const res = await Request.call('/api/online-stores/shopify-webhook/hood-generate-image', 'POST', params, undefined, undefined, );
    return res;
  }
  hoodGeneratePageContentImage = async (params: IHoodGeneratePageContentImageRequestParams) => {
    const res = await Request.call('/api/online-stores/shopify-webhook/hood-generate-page-content-image', 'POST', params, undefined, undefined, );
    return res;
  }
  hoodDownloadPDF = async (body: IHoodDownloadPDFRequestBody) => {
    const res = await Request.call('/api/online-stores/shopify-webhook/hood-download-pdf', 'POST', undefined, undefined, body, );
    return res;
  }
  hoodSendHP = async (body: IHoodSendHPRequestBody) => {
    const res = await Request.call('/api/online-stores/shopify-webhook/send-hp', 'POST', undefined, undefined, body, );
    return res;
  }
  hoodSendHPSingleLine = async (body: IHoodSendHPSingleLineRequestBody) => {
    const res = await Request.call('/api/online-stores/shopify-webhook/send-hp-single-line', 'POST', undefined, undefined, body, );
    return res;
  }
  orderToArtwork = async (body: IOrderToArtworkRequestBody) => {
    const res = await Request.call('/api/online-stores/shopify-webhook/order-to-arwork', 'POST', undefined, undefined, body, );
    return res;
  }
  manuallySendHP = async (body: IManuallySendHPRequestBody) => {
    const res = await Request.call('/api/online-stores/manually-send-hp', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new ShopAPI()