import { Col, Text, CMSLayout, Row, TouchField, Button, Input, useUIState, Select } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, ScrollView } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons } from '@expo/vector-icons';
import { Image } from 'react-native';

const CheckOrderHPData = ({ listHpSentData, postbacksData }: any) => {

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [listHpSentData]);

  const hpSentData = listHpSentData[selectedIndex]?.data;
  const sentOptions = listHpSentData.map(v => ({
    label: v.createdAt,
    value: v.createdAt,
    data: v.data,
  }));

  return (
    <Col mb2>
      <Row alignItems={'flex-start'}>
        <Col flex1 height={400} m0 mb2>
          <Text mb1>Sent to HP Data and get JSON response</Text>
          <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
            {listHpSentData.length === 0 ? (
              <Text>There is no sent data</Text>
            ) : (
              <>
                {sentOptions.length > 0 && (
                  <>
                    <Text mb0>There are {sentOptions.length} times the system sent data to HP</Text>
                    <Select
                      options={sentOptions}
                      value={sentOptions[selectedIndex]}
                      onChange={newData => {
                        const findOne = sentOptions.findIndex(v => v.value === newData.value);
                        setSelectedIndex(findOne);
                      }}
                    />
                  </>
                )}

                <Text mt1>{JSON.stringify(hpSentData, undefined, 4)}</Text>
              </>
            )}
          </ScrollView>
        </Col>
      </Row>
      <Text mb2 subtitle1>Post backs</Text>
      {postbacksData.length === 0 ? (
        <Text>There is no postback</Text>
      ) : (
        <>
          {postbacksData.map((v, vI) => {
            return (
              <Col key={'post-back-' + vI} height={400} mb1>
                <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
                  <Text>{JSON.stringify(v, undefined, 4)}</Text>
                </ScrollView>
              </Col>
            )
          })}
        </>
      )}


    </Col>
  );
};

export default CheckOrderHPData;