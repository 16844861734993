import { Col, Text, CMSLayout, Row, TouchField, Button } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons } from '@expo/vector-icons';
import { Image } from 'react-native';

const HPData: IScreen = () => {
  const { navigation } = useNavFunc();
  // const UserStore = Store.useUserStore();
  const [list, setList] = useState([]);
  const [showRaw, setShowRaw] = useState(false);
  const [hpSendData, setHpSendData] = useState<{ send: any, results: any } | null>();
  const [orderData, setOrderData] = useState({
    show: false,
    data: {
      hpEvents: [],
      orderId: '',
      printJob: undefined,
    }
  })

  useEffect(() => {
    const getData = async () => {
      const res = await Store.Api.Data.list({
        type: 'hp-log'
      }, {
        field1: 'hp',
        userId: '1',
      });
      // console.log('res.data', res.data);
      setList(res.data.data);
    };
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, []);

  const renderList = (listData = list, withOnPress = true) => {
    return (
      <>
        {withOnPress && (
          <>
            <Row m2 marginBottom={0} justifyContent={'space-between'}>
              <Text>Data events from HP</Text>
              <Button
                text='Check order'
                outline
                width={120}
                onPress={() => {
                  navigation.navigate(SCREEN.CheckOrder);
                }}
              />
            </Row>
          </>
        )}
        <Col flex1 m2={withOnPress} p2={withOnPress} round1 bgWhite>
          <Row
            height={50} stretch
            borderBottomColor={COLOR.GREY_BG}
            borderBottomWidth={1}
          >
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Timestamp</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Status</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Shopify Order Id</Text>
            </Col>
            {/* <Col flex1 m1>
            <Text color={COLOR.GREY}>Received At</Text>
          </Col> */}
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Tracking Number</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Shipping Method</Text>
            </Col>
          </Row>

          {(listData || []).map((val, i) => {
            return (
              <TouchField key={val.id} flexDirection='row'
                onPress={async () => {
                  if (!withOnPress || !val.data.shopifyOrderId) return;
                  const res = await Store.Api.PrintJob.getPrintJobByShopifyOrderId({
                    orderId: val.data.shopifyOrderId
                  });
                  setOrderData({
                    show: true,
                    data: {
                      hpEvents: list.filter(item => item.data.shopifyOrderId === val.data.shopifyOrderId),
                      orderId: val.data.shopifyOrderId,
                      printJob: res.data.data,
                    }
                  });
                  const res2 = await Store.Api.Data.list({
                    type: 'shopify-hp-log'
                  }, {
                    name: 'webhook-log',
                    field1: String(val.data.shopifyOrderId),
                  });
                  if (res2.data.success && res2.data.data && res2.data.data.length > 0) {
                    setHpSendData(res2.data.data[0].data);
                  }
                }}
              >
                <Col flex1 m1>
                  <Text>{TimeHelper.format(val.data.timestamp)}</Text>
                </Col>
                <Col flex1 m1>
                  <Text bold={val.data.status === 'shipped'}>{val.data.status}</Text>
                </Col>
                <Col flex1 m1>
                  <Text>{val.data.shopifyOrderId}</Text>
                </Col>
                <Col flex1 m1>
                  <Text>{val.data.trackingNumber}</Text>
                </Col>
                <Col flex1 m1>
                  <Text>{val.data.shippingMethod}</Text>
                </Col>
              </TouchField>
            )
          })}
        </Col>
      </>
    )
  }

  const renderOrderDetail = () => {
    const printJob: TPrintJob = orderData.data.printJob;
    // @ts-ignore
    const pregeneratedPDF = printJob?.data?.pregeneratedPDF;
    return (
      <>
        <Row m2 marginBottom={0}>
          <TouchField cirle
            middle
            onPress={() => {
              setOrderData({
                show: false,
                data: {
                  ...orderData.data,
                }
              })
              setShowRaw(false);
            }}
          >
            <Ionicons name="arrow-back-outline" size={24} color="black" />
          </TouchField>
          <Text>All Data of Order: {orderData.data.orderId}</Text>
        </Row>
        <Col flex1 m2 p2 round1 bgWhite>
          {!!printJob && (
            <>
              <Col ml1 mb2>
                <Text mb1 bold>Product Name: <Text bold>{printJob.productName}</Text></Text>
                {/* @ts-ignore */}
                {!!printJob.data?.storeProductId && (
                  // @ts-ignore
                  <Text mb1 bold>Product ID: <Text bold>{printJob.data?.storeProductId}</Text></Text>
                )}
                {!!printJob.previewUrl && (
                  <Col mb1>
                    <Text mb1 bold>Preview</Text>
                    <Image source={{ uri: printJob.previewUrl }} style={{ width: 200, height: 150 }} resizeMode='contain' />
                  </Col>
                )}
                {!!pregeneratedPDF && (
                  <Col mb1>
                    <a href={pregeneratedPDF} target="_blank">Print PDF</a>
                  </Col>
                )}
              </Col>
            </>
          )}
          <Text mb1 ml1 bold>List events received from HP</Text>
          {renderList(orderData.data.hpEvents, false)}
          {!!hpSendData && !!hpSendData.send && !!hpSendData.results && (
            <Col mt1>
              {!showRaw ? (
                <Button
                  text='Show Raw Data'
                  outline
                  width={200}
                  onPress={() => setShowRaw(true)}
                />
              ) : (
                <>
                  <Text mb1 ml1 bold>Raw Data sent to HP</Text>
                  <Col round0 backgroundColor={COLOR.GREY_BG} mb2>
                    <Text>{JSON.stringify(hpSendData.send)}</Text>
                  </Col>
                  <Text mb1 ml1 bold>Response</Text>
                  <Col round0 backgroundColor={COLOR.GREY_BG}>
                    <Text>{JSON.stringify(hpSendData.results)}</Text>
                  </Col>
                </>
              )}
            </Col>
          )}

        </Col>
      </>
    )
  }

  return (
    <CMSLayout>
      {orderData.show ? renderOrderDetail() : renderList()}
    </CMSLayout>
  )
};

HPData.routeInfo = {
  title: 'HP Data',
  path: '/hp-data',
};

export default HPData;