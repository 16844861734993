import React, { useEffect, useState, useRef, useMemo } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, Button, ShimmerLoading, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, UseMemo, modal } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Animated, Image, LayoutAnimation, View } from 'react-native';
import { Entypo, AntDesign, FontAwesome5, Feather, EvilIcons } from '@expo/vector-icons';
import { AnimHelper } from 'helpers';
import PublishProductModal from './PublishProductModal';
import { ASSETS } from 'assets';

const TAB = {
  INFO: 'INFO',
  EDITOR: 'EDITOR',
  items: [
    { title: 'Editor', key: 'EDITOR' },
    { title: 'Design Info', key: 'INFO' },
  ],
}

type TSelectResellerOption = {
  label: string,
  value: string,
  data?: any,
}

const AdditionTableData = (
  { value = [], onChange, fields }: {
    value: any,
    onChange: any,
    fields: Array<{ type: string, label: string }>,
  }) => {
  return (
    <Col mb2>
      <Row mb1 borderBottomColor={COLOR.GREY} borderBottomWidth={0.5}>
        {fields.map((v, i) => {
          return (
            <Col flex1 mr1 key={'field-' + i}>
              <Text caption>{v.label}</Text>
            </Col>
          );
        })}
        <Col width={50} onPress={() => {
          const newValue = value.slice();
          const obj = {};
          fields.forEach(field => {
            obj[field.label] = field.type === 'number' ? 0 : '';
          });
          newValue.push(obj);
          onChange(newValue);
        }}>
          <AntDesign name="plus" size={24} color="black" />
        </Col>
      </Row>
      {value.map((val, i) => {
        return (
          <Col mb1 key={'setting-var-item-' + i}>
            <Row>
              {fields.map((v, fieldIndex) => {
                return (
                  <Col flex1 mr1 key={'field-' + fieldIndex}>
                    <Input02
                      value={val[v.label]}
                      onChange={(newText) => {
                        const newValue = value.slice();
                        console.log('newValue', newValue, i, v);
                        newValue[i][v.label] = newText;
                        onChange(newValue);
                      }}
                    />
                  </Col>
                );
              })}
              <Col width={50}>
                <TouchField
                  cirle middle
                  onPress={() => {
                    onChange(value.filter((vf, vfi) => vfi !== i))
                  }}
                >
                  <EvilIcons name="trash" size={24} color={COLOR.MAIN} />
                </TouchField>
              </Col>
            </Row>
          </Col>
        )
      })}
    </Col>
  );
}

const UpsertDesign: IScreen = () => {
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const DesignStore = Store.useDesignStore();
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id, onDemand, t } = route.params || {};
  const { design, setDesign, uiState } = DesignStore.useDesign(id, false);
  const canEdit = (user?.role === 'admin')
    || (!!design?.createdByUserId && design?.createdByUserId === user?.id);
  const [listProducts, setListProducts] = useState([]);
  const uploadRef = useRef<{ showDialog: Function }>(null);

  const [expand, setExpand] = useState(false);
  const [expandUI, setExpandUI] = useState(false);

  const [curTab, setCurTab] = useState(t === 'info' ? TAB.INFO : TAB.EDITOR);

  useEffect(() => {
    navigation.setParams({
      t: curTab.toLowerCase()
    });
  }, [curTab])

  const didRemindToSave = useRef(false);

  // const [{ loading: removing }, setRemoveUI] = useUIState();
  // const [{ loading: submitting }, setSubmitUI] = useUIState();

  const [selectedProduct, setSelectedProduct] = useState<any>();

  const titleXY = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current;
  const titleScale = useRef(new Animated.Value(1)).current;

  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const onEditorMessage = async (data: { event: string, payload: any } | undefined) => {
    console.log('onEditorMessage', data);
    if (!!data && data.event === 'DESIGN_SAVE') {
      if (!!data.payload && data.payload.designId === id) {
        const res = await Store.Api.Design.detail({ id });
        if (res.data.success) setDesign(res.data.data);
      }
    }
  }

  // useEffect(() => {
  //   if (user?.role === 'reseller' && !design?.isCustomizable && curTab === TAB.EDITOR) {
  //     setCurTab(TAB.INFO)
  //   }
  // }, [user, design, curTab]);

  useEffect(() => {
    console.log('onDemand', onDemand);
    setDesign({
      ...design,
      isCustomizable: !!onDemand ? false : true,
    });
  }, [onDemand]);


  useEffect(() => {
    console.log('should run here', selectedProduct);
    if (!design?.productId) return;
    if (listProducts.length === 0) return;
    if (!!selectedProduct && selectedProduct.value === design?.productId) return;
    const find = listProducts.find(val => val.id === design?.productId);
    if (!find) return;
    console.log(find, selectedProduct)
    if (!!selectedProduct && find.name === selectedProduct.name) return;
    setSelectedProduct({
      value: design?.productId,
      label: find.name,
      data: find,
    })
  }, [design?.productId, listProducts, selectedProduct]);

  const animExpand = bool => {
    setExpand(bool);
    if (bool) {
      Promise.all([
        AnimHelper.animate(titleXY, {
          x: -15,
          y: 0,
        }, 300),
        AnimHelper.animate(titleScale, 0.7, 300)
      ])
    } else {
      Promise.all([
        AnimHelper.animate(titleXY, {
          x: 0,
          y: 0,
        }, 300),
        AnimHelper.animate(titleScale, 1, 300)
      ])
    }
    setTimeout(() => {
      setExpandUI(bool);
    }, 400);
  }

  const addToPrintJobs = async () => {
    const res = await Store.Api.PrintJob.upsert({
      clientId: 'admin',
      artworkUrls: design?.data?.artworkUrls || [],
      previewUrl: design?.image || undefined,
      isPDFDownloaded: false,
      isPrinted: false,
      isRePrinted: false,
      productId: design?.productId,
      productName: 'Design: ' + design?.name,
      designId: design?.id,
      quantity: 1,
    });
    if (res.data.error) {
      return alert(res.data.error)
    }
    alert('Successfully added a print job');
  };

  const handleDelete = async () => {
    const shouldDelete = confirm('Are you sure you want to delete?');
    if (!shouldDelete) return;
    const res = await Store.Api.Design.remove({
      id: design.id,
    });
    if (res.data.error) {
      return alert(res.data.error)
    } else if (res.data.success) {
      navigation.reset({
        index: 0,
        routes: [{ name: SCREEN.ListDesigns, params: {} }],
      });
    }
  }

  const handleSave = async () => {

    const res = await Store.Api.Design.upsert({
      id: id === 'new' ? undefined : id,
      name: design.name,
      isCustomizable: design.isCustomizable,
      sku: design.sku,
      productPdf: design.productPdf,
      productType: design.productType,
      hpSKU: design.hpSKU,
      hpAdditionData: design.hpAdditionData,
    });
    if (res.data.error) {
      alert(res.data.error);
      return;
    }
    if (id === 'new') {
      navigation.reset({
        index: 0,
        routes: [{ name: SCREEN.UpsertDesign, params: { id: res.data.data.id } }],
      });
    } else {
      alert('Save design successfully');
    }
  };

  const importData = design?.data?.importOriginalData;
  const importDataArr = useMemo(() => {
    const results = [];
    let text = ``;
    for (let key in importData) {
      results.push({
        key,
        value: importData[key]
      })
      text += `${key}: ${importData[key]}\n`
    }
    return { arr: results, text };
  }, [design]);

  const renderReady = () => {
    if (breakpoint === 'xs') return (
      <Col flex1 middle>
        <Text>Please use bigger screen to see this page.</Text>
      </Col>
    )

    const typeOptions = [
      { label: 'Card', value: 'Card' },
      { label: 'Mug', value: 'Mug' },
      { label: 'Poster', value: 'Poster' },
    ];

    console.log(design?.hpAdditionData);

    return curTab === TAB.INFO ? (
      <Col flex1>
        <Grid xs='100%' md='50%' p1 alignItems={'flex-start'}>
          {/* LEFT COLUMN */}
          <Col m1>
            <Text subtitle1 mb1>Design Name</Text>
            <Input02
              height={35}
              value={design?.name || ''}
              onChange={(v) => setDesign({ ...design, name: v })}
              mb2
            />
            <Text subtitle1 mb1>SKU</Text>
            <Input02
              height={35}
              value={design?.sku || ''}
              onChange={(v) => setDesign({ ...design, sku: v })}
              mb2
            />
            <Text subtitle1 mb1>Product Type</Text>
            <Select01
              value={typeOptions.find(o => o.value === design?.productType)}
              onChange={v => {
                setDesign({
                  ...design,
                  productType: v.value,
                })
              }}
              options={typeOptions}
              mb2
            />
            <Text subtitle1 mb0>Product PDF URL</Text>
            <Text caption mb1>If you specify Product PDF URL, it will skip Personalisation and use this instead</Text>
            <Col>
              <Input02
                height={35}
                value={design?.productPdf || ''}
                onChange={(v) => setDesign({ ...design, productPdf: v })}
                mb2
                paddingRight={35}
              />
              <TouchField width={30} height={30} borderRadius={15} middle absolute top={2} right={2} onPress={() => uploadRef.current?.showDialog()}>
                <Entypo name="upload" size={16} color="black" />
              </TouchField>
              <UploadFile
                ref={uploadRef}
                onUploaded={urls => {
                  if (!!urls && !!urls[0]) {
                    setDesign({
                      ...design,
                      productPdf: urls[0],
                    })
                  }
                }}
              />
            </Col>
            <Text subtitle1 mb0>HP SKU</Text>
            <Col>
              <Input02
                height={35}
                value={design?.hpSKU || ''}
                onChange={(v) => setDesign({ ...design, hpSKU: v })}
                mb2
              />
            </Col>

            <Text subtitle1 mb0>HP Item Attributes</Text>
            <AdditionTableData
              value={(design?.hpAdditionData?.attributes || [])}
              onChange={(newData) => {
                setDesign({
                  ...design,
                  hpAdditionData: {
                    ...design?.hpAdditionData,
                    attributes: newData,
                  }
                })
              }}
              fields={[
                { type: 'text', label: 'name' },
                { type: 'text', label: 'value' },
              ]}
            />

            <Text subtitle1 mb0>HP Stock Items</Text>
            <AdditionTableData
              value={(design?.hpAdditionData?.stockItems || [])}
              onChange={(newData) => {
                setDesign({
                  ...design,
                  hpAdditionData: {
                    ...design?.hpAdditionData,
                    stockItems: newData,
                  }
                })
              }}
              fields={[
                { type: 'text', label: 'code' },
                { type: 'text', label: 'name' },
                { type: 'number', label: 'quantity' },
              ]}
            />
            {importDataArr.text && (
              <>
                <Text subtitle1 mb1>Other Info (ready only)</Text>
                <Text mb1 lineHeight={20}>
                  {importDataArr.text}
                </Text>
              </>
            )}
            <Row>
              {canEdit && (
                <Button onPress={handleSave} height={30} width={75} borderRadius={15} text="Save" />
              )}
              {canEdit && (
                <Button
                  ml1 onPress={handleDelete} height={30} width={75}
                  backgroundColor='red'
                  bgHovered='rgba(255,255,255,0.2)'
                  borderRadius={15} text="Delete"
                />
              )}
              {Boolean(!!design?.data && Object.keys(design?.data).length > 0) && (
                <Button
                  outline
                  onPress={addToPrintJobs}
                  height={30}
                  width={150}
                  ml1
                  borderRadius={15}
                  text="Add to print jobs"
                />
              )}
            </Row>


          </Col>
          {/* RIGHT COLUMN */}
          <Col m1>
            {/* <Col>
              <Text subtitle1 mb1>In order for customize button shows on Shopify page. Please make sure:</Text>
              <Text subtitle1 mb1>1. Product tempate is <Text bold>Good_Post</Text>:</Text>
              <Text subtitle1 mb1>2. Prodcut has this tag</Text>
              <Col backgroundColor={COLOR.GREY_LIGHT} round1 p2 mb2>
                <Text color='#800000' bold>d-{design?.id}</Text>
              </Col>
              <Text mb1>For example: </Text>
              <Image source={ASSETS.TAGS_GUIDELINE} style={{ width: 300, height: 300 }} resizeMode='cover' />
            </Col> */}
          </Col>
        </Grid>

      </Col>
    ) : (
      <View
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
        }}
      >
        <iframe
          src={window.location.href.includes('iframe_dev=1') ? `http://localhost:3000/design/${id}` : `https://editor.personify.tech/${Store.Client.Site.slug}/design/${id}`}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            backgroundColor: COLOR.MAIN,
          }}
          onLoad={Store.Client.editor.initOnLoadAndOpenCommunication(onEditorMessage)}
        />
      </View>
    );
  }

  return (
    <CMSLayout requireAuthen expanded={expand}>
      <Row zIndex={2} m2={expandUI ? false : true} marginBottom={0} justifyContent={'space-between'}>
        <Animated.View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            transform: [
              { translateX: titleXY.x },
              { translateY: titleXY.y },
              { scale: titleScale },
            ],
            zIndex: 10,
            ...(expandUI ? {
              position: 'absolute',
              top: 0,
              left: 0,
            } : {})
          }}
        >
          {curTab !== TAB.EDITOR ? null : (
            !expandUI ? (
              <TouchField width={40} height={40} borderRadius={20} middle onPress={() => animExpand(true)}>
                <FontAwesome5 name="expand" size={20} color={COLOR.GREY} />
              </TouchField>
            ) : (
              <TouchField bgHovered='rgba(255,255,255,0.2)' width={40} height={40} borderRadius={20} middle onPress={() => animExpand(false)}>
                <Feather name="minimize" size={20} color={'white'} />
              </TouchField>
            )
          )}
          <Text h3 ml0 color={expandUI ? 'white' : COLOR.FONT}>{id === 'new' ? 'Create new' : design?.name}</Text>
        </Animated.View>
        <Row>
          {Boolean(expandUI) ? null : TAB.items.map((val, i) => {
            return (
              <Button
                key={val.key}
                text={val.title}
                outline={val.key !== curTab}
                height={30}
                borderRadius={15}
                width={100}
                m0
                onPress={() => {
                  if (val.key === curTab) return;
                  if (val.key === TAB.EDITOR && id === 'new') {
                    alert('Please save Design info and continue');
                    return;
                  }
                  if (val.key === TAB.INFO && !didRemindToSave.current) {
                    const shouldContinue = confirm('If you have unsaved work in the Editor tab, please press Cancel and go back to Editor tab to save. or press OK to continue');
                    if (!shouldContinue) return;
                    didRemindToSave.current = true;
                  }
                  setCurTab(val.key)
                }}
                bgHovered={val.key !== curTab ? COLOR.GREY_BG : undefined}
              />
            )
          })}
        </Row>
      </Row>
      <Col
        flex1
        zIndex={1}
        style={!expandUI ? {
          margin: 20,
          marginVertical: 10,
          borderRadius: 10,
          backgroundColor: 'white',
          borderColor: 'white',
          borderWidth: 1,
        } : {}}
      >
        {useMemo(() => {
          return uiState.errorMes ? (
            <Col flex1 middle>
              <Text color="red" subtitle1>{uiState.errorMes}</Text>
            </Col>
          ) : (
            uiState.fetching ? (
              <Row height={50} stretch>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
            ) : (
              renderReady()
            )
          );
        }, [uiState, breakpoint, curTab, listProducts, design, selectedProduct])}
      </Col>
    </CMSLayout>
  );
};

UpsertDesign.routeInfo = {
  title: 'Print Manager',
  path: '/design/:id',
};

export default UpsertDesign;