import React, { useState } from 'react';
import { Col, Text, Row, TouchField, Button, modal, Select } from 'components';
import { ScrollView, useWindowDimensions } from 'react-native';
const moment = require('moment');
import { Feather } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { COLOR } from 'const';

const DetailModal = (props) => {
  const { width } = useWindowDimensions();
  
  return (
    <Col round1 bgWhite shadow p2 width={width < 480 ? 450 : width * 0.7} height={300}>
      <Text bold mb2>Execution log</Text>
      {/* <ScrollView
        style={{
          width: '100%',
          height: 300,
        }}
      >
        
      </ScrollView> */}
      <textarea
        value={props.log} onChange={() => {}}
        style={{ flex: 1, padding: 10, outline: 'none' }}
      />
    </Col>
  )
}

const UnsubscirbeFile = (props: any) => {
  const [isRunning, setIsRunning] = useState(false);
  const l = props;
  const { result } = l;

  const showModal = () => {
    modal.show(
      <DetailModal
        data={props.data}
      />
    )
  };

  const download = async () => {
    window.open(`https://tommys.api.personify.tech/api/sites/tommys/adestra-sync/download-export/${l.name}`);
  };

  const isProcessed = !!result;

  const run = async () => {
    setIsRunning(true);
    try {
      const res = await fetch('https://tommys.api.personify.tech/api/sites/tommys/adestra-sync/sync-single-export80-file', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: l.name,
        }),
      });
      const json = await res.json();
      console.log('json', json);
      if (json.data) {
        props.onListUpdated && props.onListUpdated(json.data);
      }
    } catch(err) {

    }
    setIsRunning(false);
  };

  return (
    <Row p0>
      <Row m0 p0 flex1>
        <Text>{l.name}</Text>
        <TouchField cirle ml1 middle onPress={download}>
          <Feather name="download" size={16} color="black" />
        </TouchField>
      </Row>
      <Row m0 p0 flex={1}>
        {!isProcessed ? (
          <>
            <Text caption mr1>Unprocessed</Text>
            {/* <TouchField cirle middle>
              <FontAwesome name="play" size={16} color="black" />
            </TouchField> */}
            <Button
              text='Run'
              isLoading={isRunning}
              iconLeft={(
                <FontAwesome name="play" size={12} color={COLOR.MAIN} />
              )}
              outline
              width={100}
              height={30}
              bgHovered={COLOR.MAIN + '60'}
              onPress={run}
            />
          </>
        ) : (
          <>
            <Text caption>Done at {moment(result.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
            {!!result.data && !!result.data.log && (
              <TouchField cirle middle onPress={() => {
                modal.show(<DetailModal log={result.data.log} />)
              }}>
                <FontAwesome name="info" size={24} color="black" />
              </TouchField>
            )}
          </>
        )}
      </Row>
    </Row>
  );
}

export default UnsubscirbeFile;