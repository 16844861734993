import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState, Button } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { TGeneralData } from 'type';

interface IProps {
  userId: string,
  siteId: string,
  onSuccess: Function,
}

const UpsertUserModal = ({ userId, siteId, onSuccess }: IProps) => {

  const dataId = useRef(null);
  const [note, setNote] = useState('');
  const [{ loading, errorMes, fetching }, setUI] = useUIState();

  const UserStore = Store.useUserStore();
  const { user, setUser } = UserStore.useUser(userId);

  const [confirmPass, setConfirmPass] = useState('');

  const onChangeField = (label) => (newValue) => {
    setUser({
      ...user,
      [label]: newValue,
    })
  }

  useEffect(() => {

  }, []);

  const save = async () => {
    if (!confirmPass && user.password) {
      return alert('Confirm password not match ');
    } else if (!!confirmPass && confirmPass != user.password) {
      return alert('Confirm password not match ');
    }
    setUI({ loading: true });
    await TimeHelper.wait(300);
    const res = await Store.Api.User.upsert({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
      siteId,
    });
    if (res.data.error) {
      setUI({ loading: false });
      return alert({ loading: false, errorMes: res.data.error })
    }
    setUI({ loading: false });
    onSuccess();
    modal.hide();
  }

  return (
    <Col round1 bgWhite width={300} shadow p2>
      <Text fontSize={16} mb2>{userId === 'new' ? 'New User' : 'Update user'}</Text>
      <Col flex1>
        <Text mb0>First Name</Text>
        <Input
          value={user?.firstName || ''}
          mb1
          onChange={onChangeField('firstName')}
        />
        <Text mb0>Last Name</Text>
        <Input
          value={user?.lastName || ''}
          mb1
          onChange={onChangeField('lastName')}
        />
        <Text mb0>Email</Text>
        <Input
          value={user?.email || ''}
          mb1
          onChange={onChangeField('email')}
        />
        <Text mb0>Password</Text>
        <Input
          value={user?.password || ''}
          mb1
          password
          onChange={onChangeField('password')}
        />
        <Text mb0>Confirm Password</Text>
        <Input
          value={confirmPass}
          mb2
          password
          onChange={(v) => setConfirmPass(v)}
        />
        <Button
          isLoading={loading}
          width='100%'
          mb1
          height={45}
          text={userId === 'new' ? 'Create' : "Save"}
          onPress={save}
        />
      </Col>
    </Col>
  );
};

export default UpsertUserModal;