import Request from '../Request.utils'
export interface IListRequestQuery {
  createdByUserId?: string;
  page?: number;
}
export interface IUpsertRequestBody {
  availableForResellerIds?: object;
  data?: any;
  designId?: string;
  height?: number;
  hpAdditionData?: any;
  hpSKU?: string;
  id?: string;
  image?: string;
  isCustomizable?: boolean;
  name?: string;
  printAreas?: {
    height?: number;
    left?: number;
    top?: number;
    width?: number;
  }[];
  productId?: string;
  productPdf?: string;
  productType?: string;
  products?: any;
  sku?: string;
  width?: number;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IImportFromJsonRequestBody {
  data?: any;
}
export interface ICreateWithFrontImageRequestBody {
  frontPage?: string;
  name?: string;
  sku?: string;
}


class DesignAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/designs', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/designs', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/designs/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/designs/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  importFromJson = async (body: IImportFromJsonRequestBody) => {
    const res = await Request.call('/api/designs/import-from-json', 'POST', undefined, undefined, body, );
    return res;
  }
  createWithFrontImage = async (body: ICreateWithFrontImageRequestBody) => {
    const res = await Request.call('/api/designs/create-with-front-image', 'POST', undefined, undefined, body, );
    return res;
  }
  listProductType = async () => {
    const res = await Request.call('/api/designs/all-with-product-type', 'GET', undefined, undefined, undefined, );
    return res;
  }
}
export default new DesignAPI()