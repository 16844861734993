import { Col, Text, CMSLayout, Row, TouchField, Button } from 'components';
import ListOrders from './ListOrders';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons } from '@expo/vector-icons';
import { Image } from 'react-native';

const AllListOrders: IScreen = () => {
  const SiteStore = Store.useSiteStore();
  const [sites, setSites] = useState<Array<TSite>>([]);

  const getData = async () => {
    try {
      const { list, hasNext, total, error } = await SiteStore.getList();
      console.log({ list, hasNext, total, error });
      setSites(list);
    } catch (err) {
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ListOrders
      showAllClients
      sites={sites}
    />
  )
};

AllListOrders.routeInfo = {
  title: 'List Orders',
  path: '/all-orders',
};

export default AllListOrders;