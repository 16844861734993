import { Col, Text, CMSLayout, Row, TouchField, Button, Input, useUIState, Grid } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, ScrollView } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons } from '@expo/vector-icons';
import { Image } from 'react-native';
import { EvilIcons } from '@expo/vector-icons';
const HoodSendHPManual : IScreen = () => {
  
  const [{ loading }, setUI] = useUIState();
  const [sourceOrderId, setSourceOrderId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [town, setTown] = useState('');
  const [isoCountry, setIsoCountry] = useState('gb');
  const [postcode, setPostcode] = useState('');

  const [responseData, setResponseData] = useState();

  const send = async () => {
    setUI({ loading: true });
    setResponseData(undefined)
    try {
      const res = await Store.Api.Shop.manuallySendHP({
        orderData: {
          sourceOrderId,
          customerName
        },
        items,
        shipTo: {
          name,
          address1,
          address2,
          town,
          isoCountry,
          postcode,
        },
      });
      if (res.data.success) {
        setResponseData(res.data.data);
      }
      if (res.data.error) {
        alert(res.data.error)
      }
    } catch(err) {}
    setUI({ loading: false });
  }

  return (
    <CMSLayout requireAuthen>
      <Col m2>
        {/* <Text mb0>Please fill in Order ID</Text>
        <Row mb1>
          <Input maxWidth={270} value={orderId} onChange={setOrderId} />
          <Button
            isLoading={loading}
            solid
            ml0
            text='Search'
            height={48}
            width={100}
            onPress={checkOrder}
          />
        </Row> */}
        <Text mb2 caption>Please fill in all the fields. Make up some ids</Text>

        <Text subtitle1 mb1>Order Info</Text>
        <Grid xs='100%' md='33%' marginHorizontal={-10} mb2>
          <Col p0 m0>
            <Text mb0>Source Order Id</Text>
            <Input value={sourceOrderId} onChange={setSourceOrderId} />
          </Col>
          <Col p0 m0>
            <Text mb0>Customer Name</Text>
            <Input value={customerName} onChange={setCustomerName} />
          </Col>
        </Grid>
        <Row mb1>
          <Text subtitle1>Items</Text>
          <Button
            text='Add item'
            height={28}
            width={100}
            outline
            ml1
            onPress={() => {
              const newItems = items.slice();
              newItems.push({
                pdfUrl: '',
                sku: '',
                quantity: '',
                sourceItemId: '',
              })
              setItems(newItems);
            }}
          />
        </Row>
        {items.map((item, ii) => {
          const setItem = (label, value) => {
            const newItems = items.slice();
            newItems[ii][label] = value;
            setItems(newItems);
          }
          return (
            <Row mb2 key={'item-'+ii}>
              <Grid flex1 xs='100%' md='25%' marginHorizontal={-10} >
                <Col p0 m0>
                  <Text mb0>SKU</Text>
                  <Input value={item.sku} onChange={v => setItem('sku', v)} />
                </Col>
                <Col p0 m0>
                  <Text mb0>Quantity</Text>
                  <Input value={String(item.quantity || '')} onChange={v => setItem('quantity', +v)} />
                </Col>
                <Col p0 m0>
                  <Text mb0>PDF URL</Text>
                  <Input value={item.pdfUrl} onChange={v => setItem('pdfUrl', v)} />
                </Col>
                <Col p0 m0>
                  <Text mb0>Source Item ID</Text>
                  <Input value={item.sourceItemId} onChange={v => setItem('sourceItemId', v)} />
                </Col>
              </Grid>
              <Col ml2>
                <TouchField cirle middle onPress={() => {
                  setItems(items => items.filter((iitem, iii) => iii !== ii));
                }}>
                 <EvilIcons name="trash" size={24} color={COLOR.MAIN} />
                </TouchField>
              </Col>
            </Row>
          )
        })}
        <Text subtitle1 mt2>Ship To</Text>
        <Grid xs='100%' md='33%' marginHorizontal={-10} mb2>
          <Col p0 m0>
            <Text mb0>Name</Text>
            <Input value={name} onChange={setName} />
          </Col>
          <Col p0 m0>
            <Text mb0>Address 1</Text>
            <Input value={address1} onChange={setAddress1} />
          </Col>
          <Col p0 m0>
            <Text mb0>Address 2</Text>
            <Input value={address2} onChange={setAddress2} />
          </Col>
          <Col p0 m0>
            <Text mb0>Town</Text>
            <Input value={town} onChange={setTown} />
          </Col>
          <Col p0 m0>
            <Text mb0>ISO Country</Text>
            <Input value={isoCountry} onChange={setIsoCountry} />
          </Col>
          <Col p0 m0>
            <Text mb0>Post Code</Text>
            <Input value={postcode} onChange={setPostcode} />
          </Col>
        </Grid>

        {responseData && (
          <Col mt2>
            <Text mb1>Response Data from HP</Text>
            <ScrollView style={{ padding: 10, backgroundColor: 'white', borderRadius: 10 }}>
              <Text>{JSON.stringify(responseData, undefined, 4)}</Text>
            </ScrollView>
          </Col>
        )}

        <Button
            isLoading={loading}
            solid
            mt2
            text='Send'
            height={48}
            width={100}
            onPress={send}
          />
      </Col>
    </CMSLayout>
  )
};

HoodSendHPManual.routeInfo = {
  title: 'Send HP Manually',
  path: '/send-hp-manually',
};

export default HoodSendHPManual;