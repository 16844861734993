import { useEffect } from 'react';
import Client from './api-client';
import { useUserStore } from './User.Store';
import { usePrintJobStore } from './PrintJob.Store';
import { useDesignStore } from './Design.Store';
import { useSiteStore } from './Site.Store';
import RequestUtils from './api-client/Request.utils';

// default to 'http://localhost:3000'
// Client.setHost('http://localhost:3001');
Client.setHost('https://tommys.api.personify.tech');
console.log('client host', RequestUtils.host);
// if (window.location.href.includes('tommys.cms.personify.tech')) {
//   Client.setHost('https://tommys.api.personify.tech');
// }

export const Store = {
  Client,
  Api: Client.Api,
  useUserStore,
  usePrintJobStore,
  useDesignStore,
  useSiteStore,
}

export default Store;