import create from 'zustand';
import { TSite } from 'type';
import { useEffect, useState, Dispatch, useMemo, useRef } from 'react';
import { useUIState, IStateUI } from 'components';
import Client from './api-client';
import Store from 'store';

interface ISiteStore {
  sites: {
    [siteId: string]: TSite,
  },
  getList(): Promise<{ hasNext: boolean, list: Array<TSite>, total: number, error?: string, }>,
  useSite(id: string, useCache?: boolean) : {
    site: TSite | undefined,
    uiState: IStateUI,
    setSite: Dispatch<TSite>,
    hasDoneSomeEditing: boolean,
  },
  [otherKey: string]: any,
}

export const useSiteStore = create<ISiteStore>((set, get) => ({
  sites: {},
  getList: async () => {
    const res = await Client.Api.Site.list();
    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }
    if (res.data.data && Array.isArray(res.data.data)) {
      const obj : ISiteStore['jobs'] = {}
      res.data.data.forEach((j : TSite) => {
        obj[j.id] = j;
      });
      set({ sites: { ...get().sites, ...obj } });
    }
    return {
      hasNext: false,
      list: res.data.data,
      total: res.data.data.length,
      error: res.data.error,
    }
  },
  useSite: (id : string, useCache = true) => {
    
    const [uiState, setUI]= useUIState();
    const [site, setSite] = useState<TSite>();

    const countRef = useRef(0);
    const hasDoneSomeEditing = useMemo(() => {
      if (id === 'new') return true;
      if (!site) return true;
      countRef.current++;
      return countRef.current > 1 ? true : false;
    }, [id, site]);

    useEffect(() => {
      if (id === 'new') return;
      if (useCache && !!get().sites[id]) {
        setSite(get().sites[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Store.Api.Site.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setSite(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch(err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { site, setSite, hasDoneSomeEditing, uiState };
  }
}));
