import React from 'react';
import { Col, IColProps } from 'components';
import { TextInput, TextInputProps } from 'react-native';
import { FONT, COLOR } from 'const';
import './Input.css';

interface IProps extends IColProps {
  value?: any;
  onChange?(newValue: any): void;
  inputProps?: TextInputProps;
  placeholder?: string;
  password?: boolean;
  noOutline?: boolean;
}

const Input = ({ value, onChange, inputProps, placeholder, password, noOutline, ...props }: IProps) => {
  const outlineStyle = noOutline ? {
    outline: 'none',
  } : {
    outlineColor: COLOR.MAIN,
    outlineWidth: 2,
  }
  return (
    <Col round0 borderThin height={45} overflow='hidden' {...props}>
      <TextInput
        placeholder={placeholder}
        secureTextEntry={password}
        {...inputProps}
        style={[
          {
            width: '100%',
            padding: 10,
            fontFamily: FONT.defaultFont,
            color: '#383838',
            overflow: 'hidden',
            height: '100%',
            // @ts-ignore
            ...outlineStyle,
          },
          inputProps?.style,
        ]}
        value={value}
        onChangeText={onChange}
      />
    </Col>
  );
};

export const Input02 = (p : IProps) => {
  return (
    <Input
      height={30}
      backgroundColor={COLOR.GREY_LIGHT}
      borderColor='transparent'
      {...p}
    />
  );
}

export default Input;
