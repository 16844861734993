import Request from '../Request.utils'
export interface IDetailRequestParams {
  id: string;
}
export interface IUpsertRequestBody {
  id?: string;
  logo?: string;
  name?: string;
  settings?: any;
  slug?: string;
  theme?: any;
}


class SiteAPI {
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/sites/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  fetchSite = async () => {
    const res = await Request.call('/api/sites/info', 'GET', undefined, undefined, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/sites', 'POST', undefined, undefined, body, );
    return res;
  }
  list = async () => {
    const res = await Request.call('/api/sites', 'GET', undefined, undefined, undefined, );
    return res;
  }
  tommyRaiserEdgeWebhook = async () => {
    const res = await Request.call('/api/sites/tommys/raiser-edge-webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
  tommysRaiserEdgeToAdestra = async () => {
    const res = await Request.call('/api/sites/tommys/raiser-edge-to-adestra', 'POST', undefined, undefined, undefined, );
    return res;
  }
  tommyRaiserEdgeSyncOneConstitunent = async () => {
    const res = await Request.call('/api/sites/tommys/raiser-edge-sync-one', 'POST', undefined, undefined, undefined, );
    return res;
  }
  tommyRaiserEdgeSyncSpecificDay = async () => {
    const res = await Request.call('/api/sites/tommys/raiser-edge-sync-list-from-specific-day', 'POST', undefined, undefined, undefined, );
    return res;
  }
  tommyRaiserEdgeSyncListLog = async () => {
    const res = await Request.call('/api/sites/tommys/raiser-edge-sync/list', 'GET', undefined, undefined, undefined, );
    return res;
  }
  tommyAdestraDownloadUnsubscribeExport = async () => {
    const res = await Request.call('/api/sites/tommys/adestra-sync/download-export/:name', 'GET', undefined, undefined, undefined, );
    return res;
  }
  tommyAdestraUnsubscribeListFile = async () => {
    const res = await Request.call('/api/sites/tommys/adestra-sync/list-file-unsubscribe', 'GET', undefined, undefined, undefined, );
    return res;
  }
  tommyAdestraUnsubscribeListLog = async () => {
    const res = await Request.call('/api/sites/tommys/adestra-sync/list-handle-unsubscribe', 'GET', undefined, undefined, undefined, );
    return res;
  }
  tommyAdestraSyncSingleUnsubscribeExportFile = async () => {
    const res = await Request.call('/api/sites/tommys/adestra-sync/sync-single-unsubscribe-file', 'POST', undefined, undefined, undefined, );
    return res;
  }
  tommyAdestraExport80ListFile = async () => {
    const res = await Request.call('/api/sites/tommys/adestra-sync/list-file-export80', 'GET', undefined, undefined, undefined, );
    return res;
  }
  tommyAdestraExport80eListLog = async () => {
    const res = await Request.call('/api/sites/tommys/adestra-sync/list-handle-export80', 'GET', undefined, undefined, undefined, );
    return res;
  }
  tommyAdestraSyncSingleExport80 = async () => {
    const res = await Request.call('/api/sites/tommys/adestra-sync/sync-single-export80', 'POST', undefined, undefined, undefined, );
    return res;
  }
}
export default new SiteAPI()