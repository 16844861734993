import { Col, Text, CMSLayout, Row, TouchField, Button, Input, useUIState } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, ScrollView } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons } from '@expo/vector-icons';
import { Image } from 'react-native';

import ShopifyData from './CheckOrder.ShopifyData';
import HPData from './CheckOrder.HPData';

const TABS = [
  { key: 'shopify', label: 'Shopify Data' },
  { key: 'hp', label: 'HP Data' },
]

const CheckOrder: IScreen = () => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id, name } = route.params || {};
  const [orderData, setOrderData] = useState<any>();
  const [listSentHPData, setListSentHPData] = useState<any>([]);
  const [postbacksData, setPostbacksData] = useState<any>([]);
  const [orderId, setOrderId] = useState(!id ? '' : String(id));
  const [orderName, setOrderName] = useState(!name ? '' : String(name));
  const [{ loading }, setUI] = useUIState();
  const [errorData, setErrorData] = useState<any>();
  const isTommys = window.location.hostname.includes('tommys');
  const [tab, setTab] = useState<'shopify' | 'hp'>('shopify');

  useEffect(() => {
    if (!!id) {
      setOrderId(String(id))
    }
  }, [id]);
  useEffect(() => {
    if (!!name) {
      setOrderName(String(name))
    }
  }, [name]);

  useEffect(() => {
    if (!orderId && !orderName) return;
    setOrderData(undefined);
    setListSentHPData([]);
    setPostbacksData([]);
    checkOrder();
  }, [orderId, orderName]);

  const checkOrder = async () => {
    console.log('orderId', orderId, typeof orderId);
    setUI({ loading: true });
    setErrorData(null);
    setOrderData(null);
    try {
      const res = await Store.Api.Shop.checkOrder({
        id: orderId,
        name: orderName,
      });
      if (res.data.errorMes) return alert(res.data.errorMes);
      const list = res.data.data;
      const findOrderData = list.find(v => v.type === 'shopify-order-log');
      if (findOrderData) setOrderData(findOrderData.data);
      const listSentHPData = list.filter(v => v.type === 'shopify-hp-log');
      setListSentHPData(listSentHPData);
      const findPostbacks = list.filter(v => v.type === 'hp-log');
      if (findPostbacks.length > 0) setPostbacksData(findPostbacks.map(v => v.data));
    } catch (err) { }
    setUI({ loading: false });
  }

  return (
    <CMSLayout>
      <Col m2>
        <Row>
          <Col mr1>
            <Text mb0>Please fill in Order ID</Text>
            <Row mb1>
              <Input maxWidth={270} value={orderId} onChange={setOrderId} />
              <Button
                isLoading={loading}
                solid
                ml0
                text='Search'
                height={48}
                width={100}
                onPress={checkOrder}
              />
            </Row>
          </Col>
          <Col>
            <Text mb0>or Order Name</Text>
            <Row mb1>
              <Input maxWidth={270} value={orderName} onChange={setOrderName} />
              <Button
                isLoading={loading}
                solid
                ml0
                text='Search'
                height={48}
                width={100}
                onPress={checkOrder}
              />
            </Row>
          </Col>
        </Row>
        
        <Row mv1>
          {TABS.map((v, vI) => {
            return (
              <TouchField height={40} width={120} mr1 borderWidth={2} borderColor={v.key === tab ? COLOR.MAIN : '#ccc'} middle key={v.key} onPress={() => setTab(v.key as any)}>
                <Text>{v.label}</Text>
              </TouchField>
            )
          })}
        </Row>
        {tab === 'shopify' && (
          <ShopifyData
            orderData={orderData}
            orderId={orderId}
            errorData={errorData}
          />
        )}
        {tab === 'hp' && (
          <HPData
            listHpSentData={listSentHPData}
            postbacksData={postbacksData}
          />
        )}
      </Col>
    </CMSLayout>
  )
};

CheckOrder.routeInfo = {
  title: 'Check Order',
  path: '/check-order',
};

export default CheckOrder;