import { SCREEN } from 'const';

export const adminNav = [
  // { title: 'Job Bag', screen: SCREEN.ListPrintJobs },
  // { title: 'Print History', screen: SCREEN.PrintHistory },
  // { title: 'Reporting', screen: SCREEN.Reports },
  // { title: 'Product Library', screen: SCREEN.ListProducts },
  { title: 'List Sites', screen: SCREEN.ListSites },
  { title: 'All list Orders ', screen: SCREEN.AllListOrders },
  { title: 'Data from HP', screen: SCREEN.HPData },
  { title: 'Product Designs', screen: SCREEN.ListDesigns },
  { title: 'RE & Adestra Sync', screen: SCREEN.REAndAdestraSync },
  { title: 'Hood Send HP Manually', screen: SCREEN.HoodSendHPManual },
  // { title: 'Resellers', screen: SCREEN.ListResellers },
];

export const clientNav = [
  { title: 'List Orders ', screen: SCREEN.ListOrders, client: 'hood' },
  { title: 'Data from HP', screen: SCREEN.HPData, client: 'tommys' },
  { title: 'Product Designs', screen: SCREEN.ListDesigns },
  { title: 'RE & Adestra Sync', screen: SCREEN.REAndAdestraSync, client: 'tommys' },
  { title: 'Send HP Manually', screen: SCREEN.HoodSendHPManual, client: 'hood' },
  { title: 'Send HP Manually', screen: SCREEN.HoodSendHPManual, client: 'dev' },
  // { title: 'Product Library', screen: SCREEN.ListProducts },
  // { title: 'Product Designs', screen: SCREEN.ListDesigns },
  // { title: 'My Stores', screen: SCREEN.ListStores },
].filter(v => {
  // if (window.location.host.includes('localhost')) return true;
  // return !v.client || window.location.host.includes(v.client);
  return !v.client || v.client === 'tommys';
});