import Request from '../Request.utils'
export interface IUpsertRequestBody {
  data?: any;
  field1?: string;
  field2?: string;
  id?: string;
  name?: string;
  publicPermission?: {
    c?: boolean;
    d?: boolean;
    r?: boolean;
    u?: boolean;
  };
}
export interface IUpsertRequestParams {
  type: string;
}
export interface IListRequestParams {
  type: string;
}
export interface IListRequestQuery {
  allClients?: number;
  field1?: string;
  field2?: string;
  name?: string;
  page?: number;
  userId?: string;
}
export interface IDetailRequestParams {
  id: string;
  type: string;
}


class DataAPI {
  upsert = async (params: IUpsertRequestParams, body: IUpsertRequestBody) => {
    const res = await Request.call('/api/general-data/:type', 'POST', params, undefined, body, );
    return res;
  }
  list = async (params: IListRequestParams, query: IListRequestQuery) => {
    const res = await Request.call('/api/general-data/:type', 'GET', params, query, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/general-data/:type/:id', 'GET', params, undefined, undefined, );
    return res;
  }
}
export default new DataAPI()