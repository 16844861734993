import { Col, Text, Button, Input, useUIState } from 'components';
import { IScreen } from 'type';
import React, { useState, useRef, useEffect } from 'react';
import { COLOR, SCREEN } from 'const';
import { SVG } from 'assets';
import Store from 'store';
import { useNavFunc } from 'navigation';
import {
  Animated,
  useWindowDimensions,
} from 'react-native';
import LoginAnimated from './LoginAnimated';

const FORM_WIDTH = 320;
const FORM_HEIGHT = 370;

const HEADER_LOGIN_WIDTH = 269 * 0.7;
const HEADER_LOGIN_HEIGHT = 79 * 0.7;

const LOGO_HEIGHT = { BEFORE: 120, AFTER: HEADER_LOGIN_HEIGHT };

const calcFormInCenterPosition = (w, h) => {
  return {
    x: (w - FORM_WIDTH) / 2,
    y: (h - FORM_HEIGHT) / 2,
  }
};

const animate = (
  value : Animated.Value | Animated.ValueXY,
  toValue: Animated.TimingAnimationConfig['toValue'],
  duration: Animated.TimingAnimationConfig['duration'],
  delay : Animated.TimingAnimationConfig['delay'] = 0,
) => new Promise((resolve) => {
  Animated.timing(value, {
    toValue,
    duration,
    delay,
    useNativeDriver: false,
  }).start(({ finished }) => {
    resolve(finished);
  });
});

const Login: IScreen = () => {
  const { reset, route } = useNavFunc();
  const { redirect }: any = route.params || {};
  const { width: innerWidth, height: innerHeight } = useWindowDimensions();
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [showAfterLoginLayout, setShowAfterLoginLayout] = useState(false);

  // anim params
  const position = useRef(new Animated.ValueXY(calcFormInCenterPosition(innerWidth, innerHeight))).current;
  useEffect(() => {
    Animated.timing(position, {
      toValue: calcFormInCenterPosition(innerWidth, innerHeight),
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [innerWidth, innerHeight]);

  const logoHeight = useRef(new Animated.Value(LOGO_HEIGHT.BEFORE)).current;
  const containerWidth = useRef(new Animated.Value(FORM_WIDTH)).current;
  const containerHeight = useRef(new Animated.Value(FORM_HEIGHT)).current;
  const inputOpacity = useRef(new Animated.Value(1)).current;

  // const logoHeight = containerHeight.interpolate({
  //   inputRange: [HEADER_LOGIN_HEIGHT, FORM_HEIGHT],
  //   outputRange: [LOGO_HEIGHT.AFTER, LOGO_HEIGHT.BEFORE],
  //   extrapolate: 'clamp',
  // })

  const doRedirect = () => {
    if (redirect) {
      reset(redirect);
    } else {
      reset(SCREEN.Home);
    }
  }

  const onLoginSuccess = async () => {
    if (innerWidth < 480) return doRedirect();

    await animate(inputOpacity, 0, 300, 300);
    setShowWelcomeMessage(true);

    await animate(containerHeight, 120, 300, 4000);
    setShowWelcomeMessage(false);
    
    await Promise.all([
      animate(position, { x: 0, y: 0 }, 500, 0),
      animate(containerHeight, HEADER_LOGIN_HEIGHT, 300, 200),
      animate(containerWidth, HEADER_LOGIN_WIDTH, 300, 200),
      animate(logoHeight, LOGO_HEIGHT.AFTER, 300, 200),
    ]);
    setShowAfterLoginLayout(true);

    setTimeout(() => {
      doRedirect();
    }, 500);
  }

  return (
    <LoginAnimated
      logoHeight={logoHeight}
      width={containerWidth}
      height={containerHeight}
      position={position}
      inputOpacity={inputOpacity}
      onLoginSuccess={onLoginSuccess}
      showWelcomeMessage={showWelcomeMessage}
      showAfterLoginLayout={showAfterLoginLayout}
    />
  )
}

Login.routeInfo = {
  title: 'Login',
  path: '/login',
}

export default Login;