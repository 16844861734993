import React, { useEffect, useState } from 'react';
import { Col, Text, Row, TouchField, Button, modal, Select } from 'components';
import { FontAwesome } from '@expo/vector-icons';
import { ActivityIndicator, ScrollView, useWindowDimensions } from 'react-native';
const moment = require('moment');
import RequestUtils from 'store/api-client/Request.utils';
import { Ionicons } from '@expo/vector-icons';
import { Foundation } from '@expo/vector-icons';

const OriginalDataModal = (props) => {
  const { width } = useWindowDimensions();
  const [selectedId, setSelectedId] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState<any>([]);
  const [filtered, setFiltered] = useState<any>([]);

  useEffect(() => {
    const logUrl = RequestUtils.host + props.data.originalDataFile;
    console.log(logUrl);
    fetch(logUrl)
      .then(res => res.json())
      .then(json => {
        console.log('JSON', json);
        setList(json?.value || []);
      });

    const filteredUrl = RequestUtils.host + props.data.filteredDataFile;
    fetch(filteredUrl)
    .then(res => res.json())
    .then(json => {
      setFiltered(json || []);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [props.data])

  const downloadOriginalFile = () => {
    // download list as json file
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(list, null, 2)], { type: 'application/json' });
    element.href = URL.createObjectURL(file);
    element.download = "original-data.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  if (isLoading) return (
    <Col middle round1 bgWhite shadow p2 width={width < 480 ? 450 : width * 0.7}>
      <ActivityIndicator />
    </Col>
  )

  console.log('filtered', filtered);
  

  const options = filtered.map(v => {
    const constituent_id = v.lookup_id;
    const email = v.email?.address || '';
    const name = v.name || '';
    return {
      label: `${name} - ${constituent_id}${email ? ` - ${email}` : ''}`,
      value: v.id,
      data: v,
    }
  });
  console.log('options', options);

  const displayList = filtered.length === 0 ? list : filtered;

  return (
    <Col round1 bgWhite shadow p2 width={width < 480 ? 450 : width * 0.7}>
      <Text bold mb2>Oringal API Data ({list.length})</Text>
      <Col>
        <Row mb0>
          <Col width={150}>
            <Text bold>Target time</Text>
          </Col>
          <Col flex1 ml1>
            <Text>{props.data.targetTime}</Text>
          </Col>
        </Row>
        <Row mb0>
          <Col width={150}>
            <Text bold>API URL</Text>
          </Col>
          <Col flex1 ml1>
            <Text>{props.data.apiPath}</Text>
          </Col>
        </Row>
        <Row>
          <Col width={150}>
            <Text bold>Download Original Data (JSON)</Text>
          </Col>
          <Row flex1 ml1>
            <TouchField cirle middle
              onPress={downloadOriginalFile}
            >
              <Foundation name="download" size={24} color="black" />
            </TouchField>
            <Text ml1>{list.length} records</Text>
          </Row>
        </Row>
        {props.data.filteredDataFile && (
          <Row>
            <Col width={150}>
              <Text bold>Download Filtered Data (JSON)</Text>
            </Col>
            <Row flex1 ml1>
              <TouchField cirle middle
                onPress={() => {
                  // download list as json file
                  const element = document.createElement("a");
                  const file = new Blob([JSON.stringify(filtered, null, 2)], { type: 'application/json' });
                  element.href = URL.createObjectURL(file);
                  element.download = `filtered-data-${props.data.targetTime}.json`;
                  document.body.appendChild(element); // Required for this to work in FireFox
                  element.click();
                }}
              >
                <Foundation name="download" size={24} color="black" />
              </TouchField>
              <Text ml1>{filtered.length} records</Text>
            </Row>
          </Row>
        )}
      </Col>
      <Select mv1
        value={options.find(o => o.value === selectedId)}
        options={options}
        onChange={(newValue) => {
          setSelectedId(newValue.value);
        }}
        innerProps={{
          placeholder: 'Find a constitunent..'
        }}
      />
      <ScrollView
        style={{
          width: '100%',
          height: 300,
        }}
      >
        <Row>
          <Col flex1 m0 p0>
            <Text bold>Email</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Name</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Constituent ID</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>RE Link</Text>
          </Col>
        </Row>
        {(displayList || []).filter(v => !selectedId ? true : v.id === selectedId).map((v, i) => {
          return (
            <Row key={'riww-' + i}>
              <Col flex1 m0 p0>
                <Text opacity={v.email?.address ? 1 : 0.6}>{v.email?.address || 'Email unavailable'}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.name}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.lookup_id}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Button
                  text='Open RE link'
                  iconLeft={<FontAwesome name="external-link" size={18} color="white" />}
                  onPress={() => {
                    window.open(`https://host.nxt.blackbaud.com/constituent/records/${v.id}?envId=p-nqPdGw1H2E2ZH-QBih_Z2w&svcId=renxt`, '_blank');
                  }}
                />
              </Col>
            </Row>
          )
        })}
      </ScrollView>
    </Col>
  )
}

const DetailModal = (props) => {
  const { width } = useWindowDimensions();
  const [selectedEmail, setSelectedEmail] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [{ errors, optInList, optOutList, results }, setData] = useState<any>({
    errors: [],
    optInList: [],
    optOutList: [],
    results: [],
  });

  useEffect(() => {
    console.log('props.data', props.data);
    if (!props.data.logFile && !props.data.logPath) {
      setData(props.data);
      setIsLoading(false);
      return;
    }
    const logUrl = RequestUtils.host + (props.data.logFile || props.data.logPath);
    console.log(logUrl);
    fetch(logUrl)
      .then(res => res.json())
      .then(json => {
        setData(json);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.data])

  if (isLoading) return (
    <Col middle round1 bgWhite shadow p2 width={width < 480 ? 450 : width * 0.7}>
      <ActivityIndicator />
    </Col>
  )
  console.log('results', results);
  const options = (results || []).map(v => ({ label: v.email, value: v.email, data: v }));
  return (
    <Col round1 bgWhite shadow p2 width={width < 480 ? 450 : width * 0.7}>
      <Text bold mb2>Details</Text>
      <Select mb2
        value={options.find(o => o.value === selectedEmail)}
        options={options}
        onChange={(newValue) => {
          setSelectedEmail(newValue.value);
        }}
      />
      <ScrollView
        style={{
          width: '100%',
          height: 300,
        }}
      >
        <Row>
          <Col flex1 m0 p0>
            <Text bold>Email</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Category</Text>
          </Col>
          <Col width={70} m0 p0>
            <Text bold>Consent</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Adestra Link</Text>
          </Col>
        </Row>
        {(results || []).filter(v => !selectedEmail ? true : v.email === selectedEmail).map((v, i) => {
          return (
            <Row key={'riww-' + i}>
              <Col flex1 m0 p0>
                <Text>{v.email}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.category}</Text>
              </Col>
              <Col width={70} m0 p0>
                <Text>{v.consent}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Button
                  text='Open Adestra link'
                  iconLeft={<FontAwesome name="external-link" size={18} color="white" />}
                  onPress={() => {
                    window.open(v.adestraLink, '_blank');
                  }}
                />
              </Col>
            </Row>
          )
        })}
      </ScrollView>
    </Col>
  )
}

const LogItem = (props: any) => {
  const l = props;

  const showModal = () => {
    modal.show(
      <DetailModal
        data={props.data}
      />
    )
  };

  return (
    <Row p0>
      <Col m0 p0 flex1>
        <Text>{moment(l.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
      </Col>
      <Col m0 p0 flex1>
        <Text>{l.field1}</Text>
      </Col>
      <Col m0 p0 flex1>
        {l.data.originalDataFile && (
          <TouchField cirle middle
            onPress={() => {
              modal.show(
                <OriginalDataModal
                  data={props.data}
                />
              )
            }}
          >
            <Ionicons name="open-outline" size={16} color="black" />
          </TouchField>
        )}
      </Col>
      <Row m0 p0 flex={2}>
        {/* <Text mr1>Results: {l.data.results.length} | Erros: {l.data.errors.length} | OptIn: {l.data.optInList.length} | OptOut: {l.data.optOutList.length}</Text> */}
        <Button
          text='View detail'
          iconLeft={<FontAwesome name="list" size={12} color="white" />}
          onPress={showModal}
        />
      </Row>
    </Row>
  );
}

export default LogItem;